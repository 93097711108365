import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import INFO from "../data/info";
import { useState, useEffect } from "react";
import "./Styles/about.css";
import Logo from "../Components/Logo";
import Socials from "../Components/Social";

function About(){
	const [width, setWidth ] =  useState(80);
	useEffect(()=>{
		const handleScroll = ()=>{
			const scrolly = window.scrollY;
			const currentWidth = scrolly/4;
			if(currentWidth > width){
				setWidth(40);
	
			}
			else if(scrolly < 100){
				setWidth(80);
			}
		}
		window.addEventListener("scroll",handleScroll)

	},[width])

	return (
			<div className="page-content">
				<NavBar active="about" />
				<div className="content-wrapper">
					<div className="about-logo-container">
						<div className="about-logo">
							<Logo Source="../logo.png" Width={width}/>
						</div>
					</div>

					<div className="about-container">
						<div className="about-main">
							<div className="about-right-side">
								<div className="title about-title">
									{INFO.about.title}
								</div>

								<div className="subtitle about-subtitle">
									{INFO.about.description}
								</div>
							</div>

							<div className="about-left-side">
								<div className="about-image-container">
									<div className="about-image-wrapper">
										<img
											src="homepage.jpg"
											alt="about"
											className="about-image"
										/>
									</div>
								</div>

								<div className="about-socials">
								<Socials/>
								</div>
							</div>
						</div>
						<div className="about-socials-mobile">
						<Socials/>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
	)
};

export default About;
