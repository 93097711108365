import React from "react"
import { Button} from 'antd';
import {DownloadOutlined} from '@ant-design/icons'
//import {pdf} from "../data/resume.pdf"

export default function DownloadResume(props:{name:string}) {

    return (
        <div className={`my-resume ${props.name}`}>
        <a href={process.env.PUBLIC_URL+"/resume.pdf"} target="_blank" rel="noreferrer" download> 
        <Button type="primary" icon={<DownloadOutlined />}>Download My Resume</Button>
       </a>
        </div>)
}
