import React from "react";
import "./logo.css"

interface ILogo{
    Width: number,
    Source: string,
}

function Logo(props:ILogo){
    return(<img className="logo" src={props.Source} width={props.Width} alt="" />)
}

export default Logo;