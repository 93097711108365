import React from "react";
import '../Common/projectlist.css'
import Project from "./Project";
import Data from "../data/info"

function ProjectList(){
    return(<div className="all-projects-container">
        {
            Data.projects.map((x,index)=>(
                <Project ProjectUrl={x.projectUrl} ProjectLogo={x.projectLogo} Title={x.title} Description={x.description} key={index} />
            ))
        }
        </div>
    )
}

export default ProjectList;