import Home from './Pages/Home'
import './App.css';
import {Routes, Route, HashRouter} from 'react-router-dom'
import Projects from './Pages/Projects';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Resume from './Pages/Resume';

function App() {
  return (
    <div className="App">
      <HashRouter>
      <Routes>
        <Route path="" element={<Home/>}/>
        <Route path="projects" element={<Projects/>}/>
        <Route path="about" element={<About/>}/>
        <Route path="contact" element={<Contact/>}/>
        <Route path="resume" element={<Resume/>}/>
      </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
