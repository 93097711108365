import React, { useState } from "react"
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { StepForwardOutlined, StepBackwardOutlined} from "@ant-design/icons"
import { Document, Page ,pdfjs} from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "../Pages/Styles/resume.css"
import DownloadResume from "../Components/DownloadResume"

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js"

function Resume(){
    // const [numPages, setnumPages] = useState(0);
    // const [currentPages, setcurrentPages] = useState(1);

    // function HandleOnLoad({ numPages }: { numPages: number }) : void{
    //     setnumPages(numPages);
    // }

    // function HandlePageClick(type: number) {
    //    let current = type === 1 ? currentPages + 1 : currentPages - 1;
    //    if(current <= numPages && current > 0){
    //         setcurrentPages(current);
    //    }
    // }
    
    return (
        <div className="page-content">
            	<NavBar active="resume" />
				<div className="content-wrapper">
					<div className="contact-logo-container">
						<div className="contact-logo">
                        <div className="about-logo">
							<img src="../logo.png" className="logo" width="40" alt=""/>
						</div>
						</div>
					</div>

					<div className="resume-container">
                    
                    {/* <div className="d-flex pdf-content">
                        <Document file={{url:"https://iamakhilsree.in/resume.pdf"}} onLoadSuccess={HandleOnLoad} >
                            <Page pageNumber={currentPages} renderTextLayer={false} />
                        </Document>
                    </div>
                    {
                        numPages > 0 &&
                        <>
                        <div className="d-flex pdf-button">
                        <StepBackwardOutlined onClick={()=>HandlePageClick(0)} label="Prev"/>
                        <StepForwardOutlined onClick={()=>HandlePageClick(1)} label="Next"/>
                        </div>
                        
                        </>
                    } */}
                    <object className="pdf-contents-object" data="../resume.pdf" type="application/pdf">   </object>
                    <DownloadResume name= "d-flex pdf-button"/ >
                    <Footer/>
                    </div>
          </div>
        </div>
    )
}

export default Resume;