import React from "react";
import {Link} from "react-router-dom";
import '../Components/navbar.css'

function NavBar(prop: {active : "home" | "about" | "projects" | "resume" | "contact"}){
    return(<div className="nav-container">
        <nav className="navbar">
            <div className="nav-background">
                <ul className="nav-list">
                    <li className={prop.active == "home" ? "nav-item active" : "nav-item"}><Link to="/">Home</Link></li>
                        <li className={prop.active == "about" ? "nav-item active" : "nav-item"}><Link to="/about">About</Link></li> 
                        <li className={prop.active == "projects" ? "nav-item active" : "nav-item"}><Link to="/projects">Projects</Link></li>
                        <li className={prop.active == "resume" ? "nav-item active" : "nav-item"}><Link to="/resume">Resume</Link></li>
                        <li className={prop.active == "contact" ? "nav-item active" : "nav-item"}><Link to="/contact">Contact</Link></li>
                </ul>
            </div>
        </nav>
    </div>);
}

export default NavBar;
