import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

interface IProject{
    ProjectUrl : string,
    ProjectLogo : string [],
    Title: string,
    Description: string,
}

function Project(props:IProject){
return(
<div className="all-projects-project">
<div className="project">
<a href={props.ProjectUrl}  target="_blank">
    <div className="project-container">
        <div className="project-logo-main row">
        {
            props.ProjectLogo.map((x) =>
            (                
                <div className="project-logo"> <img src={x} alt="logo"/></div>
            ))
        }
        </div>
        <div className="project-title">{props.Title}</div>
        <div className="project-description">{props.Description}
        </div>
        <div className="project-link">
        <div className="project-link-icon">
		<FontAwesomeIcon icon={faLink} />
		</div>
        <div className="project-link-text">View</div>
        </div>
    </div>
</a>
</div>
</div>);
}

export default Project