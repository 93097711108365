import React from "react"
import NavBar from "../Components/NavBar"
import ProjectList from "../Common/ProjectList"
import "../Pages/Styles/project.css"
import Footer from "../Components/Footer"

function Projects(){
    return(
        <div className="page-content">
            <NavBar active="projects"/>
                            <div className="content-wrapper">
                                <div className="projects-logo-container">
                                    <div className="projects-logo">
                                        <a href="/">
                                            <img src="../logo.png" alt="logo" className="logo" width="46"/>
                                        </a>
                                    </div>
                                </div>
                                <div className="projects-container">
                                    <div className="title projects-title">Things I’ve made trying to put my dent in the universe.</div>
                                    <div className="subtitle projects-subtitle">I've experience in Software Development Life Cycle SDLC involving the 
                                        requirement gathering, analysis, logical physical architectural modeling, design,  development, unit testing,
                                        implementation, and production support. I have sound knowledge in Object Oriented Concepts. I am expertise in software design 
                                        and development using ASP.NET technologies. I am able to work as a team player as well as individually. 
                                        Highly organized, dedicated with a positive attitude along with the strong analytical and troubleshooting skills.
                                    </div>
                                    <ProjectList/>
                                   <Footer/>
                                </div>
                            </div>
            </div>
    )

}

export default Projects;