const INFO = {
    main:{email: "iamakhi007@gmail.com"},
    homepage: {
        title: "Full Stack .NET Developer",
        description:
            "I am a full stack developer with expertise in Asp.net technologies along with Azure, React.js. I've experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer. Mainly focused on the technologies Asp.net MVC, Asp.Net Core, React.js, Azure and Devops",
    },
    socials: {
		github: "https://github.com/iamakhilsreekumar",
		linkedin: "https://www.linkedin.com/in/akhil-s-1b1672117",
		instagram: "https://www.instagram.com/akhil_sree_007",
	},
    projects:[
    {
        //projectUrl: //"https://planning.gb.intelliflo.net/"
        projectUrl:"https://www.intelliflo.com/our-solutions/intelliflo-office/features-and-functionality/",
        projectLogo:["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/csharp/csharp.png",
            "../logo512.png",
            "../Azure_Logo.png",
            "./mongodb-icon.png",],
        title:"Intelliflo - Planner Application",
        description:"I worked as Full stack developer mainly in two application named as Inteflo Office and Intelliflo planning application. I've Worked on various enhancment developments in both front end and backend using React.js, Asp.net MVC , GraphQL API, Mongo DB and MSSQL.",
    },
    {
        projectUrl:"https://apps.apple.com/us/app/hca-inspire/id1439205162",
        projectLogo:["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/csharp/csharp.png",
                "../asp.net.png",
                "../Azure_Logo.png",
                "../azure_service.png",
                "https://code.benco.io/icon-collection/azure-icons/Azure-Cosmos-DB.svg"],
        title:"Missmeds Mobile Application",
        description:"Worked as Backend developer on developing mutiple APIs which is consumed by HCA Mobile App.For the feature implementation consumed and created various Azure services from Azure Service Bus, Azure App services, Azure App Insights, Azure Cosmos DB.",
    },
    {
        projectUrl:"https://www.flybooking.com/",
        projectLogo:["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/csharp/csharp.png",
            "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png"],
        title:"Flybooking",
        description:"Application is used for booking Flights and Hotels which has both B2B and B2C. As a full stack developer, Implemented various UI screens, integrated Flight, Hotel APIs and various Payment Gateways in this travel web application.",
    },
    {
        projectUrl:"https://www.hinfly.com/",
        projectLogo:["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/csharp/csharp.png",
            "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png"],
        title:"HiNFly",
        description:"Application is used for booking Flight and Hotel which has both B2B and B2C.",
    },
    {
        projectUrl:"https://agreliantgenetics.com/",
        projectLogo:["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/csharp/csharp.png",
            "../asp.net.png",
            "../Azure_Logo.png",
            "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png"],
        title:"Agreliant ERP",
        description:"I have worked here as a full stack developer. Application is manging Agreliant products and its sales, various reports, which has both B2B and B2C.",
    },
    ],
    work:[
    {
        CompanyName:"Caxita Technologies, Cochin",
        logo: "",
        FromYear:new Date("11-09-2016 19:02:00"),
        EndYear:new Date("07-07-2019 19:02:00"),
        IsCurrentCompany: false,
    },
    {
        CompanyName:"Cognizant Technologies, Cochin",
        logo: "",
        FromYear:new Date("07-07-2019 19:02:00"),
        EndYear:new Date("07-07-2021 19:02:00"),
        IsCurrentCompany: false,
    },
    {
        CompanyName:"Qburst Technologies, Cochin",
        logo: "",
        FromYear:new Date("07-09-2021 19:02:00"),
        EndYear:new Date("07-07-2021 19:02:00"),
        IsCurrentCompany: true,
    }],
  about: {
    title: "I’m Akhil Sreekumar, an Indian. I am passionate about solving business problems through human-centered design.",
    description:
        "I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are went to production and available to the users. Please feel free to check out the URL in project section and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
},
}

export default INFO;