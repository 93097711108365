import NavBar from '../Components/NavBar';
import ProjectList from '../Common/ProjectList'
import Footer from '../Components/Footer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLinkedin,
	faGithub,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { useState, useEffect } from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import INFO from '../data/info';
import './Styles/homepage.css'
import CardTimeline from '../Components/CardTimeline';
import Logo from "../Components/Logo";
import DownloadResume from '../Components/DownloadResume';

export function Home()
{
    const [width, setWidth ] =  useState(80);
        useEffect(()=>{
            const handleScroll = ()=>{
                const scrolly = window.scrollY;
                if(scrolly > 100){
                    setWidth(40);
        
                }
                else{
                    setWidth(80);
                }
            }
            window.addEventListener("scroll",handleScroll)
    
        },[width])

    return(
    <div className="page-content">
        <NavBar active="home"/>
    <div className="content-wrapper">
    <div className="homepage-logo-container">
        <div className="homepage-logo">
            <Logo Source="../logo.png" Width={width}/>
        </div>
    </div>
        <div className="homepage-container">
                <div className="homepage-first-area">
                    <div className="homepage-first-area-left-side">
                        <div className="title homepage-title">{INFO.homepage.title}</div>
                        <div className="subtitle homepage-subtitle">{INFO.homepage.description}
                        </div>
                        </div>
                        <div className="homepage-first-area-right-side">
                            <div className="homepage-image-container">
                                <div className="homepage-image-wrapper">
                                    <img src="homepage.jpg" alt="about" className="homepage-image" />
                                </div>
                            </div>
                        </div>
                </div>

                <div className="homepage-socials">
							<a href={INFO.socials.linkedin}
							    target="_blank"
								rel="noreferrer">
							   <FontAwesomeIcon
									icon={faLinkedin}
									className="homepage-social-icon"
								/>
							</a>	
                            <a href={INFO.socials.github}
							    target="_blank"
								rel="noreferrer">
							   <FontAwesomeIcon
									icon={faGithub}
									className="homepage-social-icon"
								/>
							</a>
                            <a href={INFO.socials.instagram}
							    target="_blank"
								rel="noreferrer">
							   <FontAwesomeIcon
									icon={faInstagram}
									className="homepage-social-icon"
								/>
							</a>						
				</div>
                <div className="homepage-after-title">
                    <CardTimeline CardTitle="Professional Experience" CardIcon={faBriefcase} TimeLineItems={INFO.work}/>
                </div>
                <ProjectList/>
                <Footer/>
            </div>
        </div>
    </div>
    );
}

export default Home;