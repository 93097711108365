import react, { Children } from 'react'
import IconFont from '@ant-design/icons'
import {Timeline} from "antd"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TimelineItemProps } from 'antd/es/timeline/TimelineItem'
import {IconDefinition } from "@fortawesome/free-solid-svg-icons";
import logoSVG from "../logo.svg"
import "./cardtimeline.css"
interface ICard
{
    CardTitle:string,
    CardIcon:IconDefinition,
    TimeLineItems:IWork[],
}

interface IWork
{
    CompanyName:string,
    logo: string,
    FromYear:Date,
    EndYear:Date,
    IsCurrentCompany: boolean,
}
// {CompanyName:string,logo: string,
//     FromYear:Date,
//     EndYear:Date,
//     IsCurrentCompany: boolean}
function ConvertToTimelineItem(workArray:IWork[]): TimelineItemProps[]{
    const listTimeline : TimelineItemProps[] =  workArray.map((s)=>(
        {
            children: s.IsCurrentCompany ? s.CompanyName + '(' + s.FromYear.getFullYear() + ' -  now)' 
                          : s.CompanyName + '(' + s.FromYear.getFullYear()  + ' - '+ s.EndYear.getFullYear() +')'
        }
    ))
    return listTimeline
}

function CardTimeline(props:ICard){

return (        
 <div className="card">
    <div className="card-container">
        <div className="card-header">
            <div className="card-icon">
               <FontAwesomeIcon icon={props.CardIcon}/>
            </div>
            <div className="card-title">
            {props.CardTitle}
            </div>
        </div>
        <div className="card-body">
        <Timeline items={ConvertToTimelineItem(props.TimeLineItems)} mode='left' />
        </div></div></div> )
}

export default CardTimeline